import { ResponseSimilarItems } from "@/services/swagger/auth/models"
import { createContext, useContext } from "react"

export type StartContextValue = {
  isSuccessUpload: boolean
  isFindingSimilarItems: boolean
  file?: File
  image?: string
  progress?: number
  similarItems?: ResponseSimilarItems
  needAuth?: boolean
  setNeedAuth?: (boolean: boolean) => void
  setSimilarItems?: (items: ResponseSimilarItems) => void
  onCancel: () => unknown
  onUpload: (file?: File, src?: string) => unknown
  onDeleteImage: () => unknown
}

export const StartContext = createContext<StartContextValue | null>(null)

export function useStartContext() {
  const value = useContext(StartContext)
  if (!value) {
    throw new Error("should render in <StartContext.Provider>")
  }

  return value
}
